import React, { useEffect, useState } from "react";

import { graphql, Link } from "gatsby";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import SEO from "../seo/seo";
import Breadcrumb from "../breadcrumb/breadcrumb";
import MortgageCalculator from "../calculators/mortgage-calculator/calculator";
import Icon from "../custom-widgets/icon";
import Button from "../custom-widgets/button";
import FaqAccordion from "../faq/faq-accordion";
import StretchedLinkCards from "../card/stretched-link-cards";
import LoanOfficerSearch from "../loan-officers/loan-officer-search";
import List from "../custom-widgets/list";
import VimeoVideoFrame from "../custom-widgets/vimeo-video-frame";
import HelpSection from "../home-loans/help-section";

import useCardBlogsData from "../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../helpers/getFeaturedCardBlogData";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

// GraphQL Query
export const query = graphql`
  query imageData(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    card01: file(relativePath: { eq: "cards/thumbnail-heloc-to-customize-730.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    card02: file(relativePath: { eq: "cards/thumbnail-qualify-for-heloc-04182023-730.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    card03: file(relativePath: { eq: "cards/thumbnail-heloc-student-loans-071223.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
    }
  }
`;

const StateHelocTemplate = ({ data }) => {
  const stateData = data.strapiBranchState;
  const [showLOSearchModal, setShowLOSearchModal] = useState(false);
  const handleCloseLOSearchModal = () => setShowLOSearchModal(false);
  const handleShowLOSearchModal = () => {
    setShowLOSearchModal(true);
  };
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;

  const pageData = {
    AZ: {
      heroAltText: "Arizona home with spring flowers.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Arizona? Learn about current rates and WaFd Bank's HELOC offering today.",
      city1: "Phoenix",
      city2: "Mesa",
      url1: "/locations/arizona/phoenix",
      url2: "/locations/arizona/mesa/mckellips-road"
    },
    CA: {
      heroAltText: "New home construction in California.",
      ogImgDateStamp: "110424",
      description:
        "Looking for a home equity line of credit or HELOC rates in California? Learn about current rates and WaFd Bank's HELOC offering today.",
      city1: "Santa Rosa",
      city2: "El Segundo",
      url1: "/locations/california/santa-rosa",
      url2: "/locations/california/el-segundo"
    },
    NM: {
      heroAltText: "New Mexico home with spring flowers.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in New Mexico? Learn about rates and WaFd Bank's HELOC offering in the Albuquerque area and beyond.",
      city1: "Albuquerque",
      city2: "Santa Fe",
      url1: "/locations/new-mexico/albuquerque",
      url2: "/locations/new-mexico/santa-fe"
    },
    NV: {
      heroAltText: "Nevada home in the spring.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Nevada? Learn about rates and WaFd Bank's HELOC offering in the Las Vegas area and beyond.",
      city1: "Las Vegas",
      city2: "Fallon",
      url1: "/locations/nevada/las-vegas",
      url2: "/locations/nevada/fallon"
    },
    OR: {
      heroAltText: "Oregon home with spring flowers.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Oregon? Learn about current rates and WaFd Bank's HELOC offering today.",
      city1: "Portland",
      city2: "Klamath Falls",
      url1: "/locations/oregon/portland",
      url2: "/locations/oregon/klamath-falls"
    },
    ID: {
      heroAltText: "Idaho home with spring flowers.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Idaho? Learn about current rates and WaFd Bank's HELOC offering today.",
      city1: "Boise",
      city2: "Nampa",
      url1: "/locations/idaho/boise",
      url2: "/locations/idaho/nampa"
    },
    UT: {
      heroAltText: "Utah home in the spring.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Utah? Learn about rates and WaFd Bank's HELOC offering in the Salt Lake City area and beyond.",
      city1: "Salt Lake City",
      city2: "Logan",
      url1: "/locations/utah/salt-lake-city",
      url2: "/locations/utah/logan"
    },
    WA: {
      heroAltText: "Washington home with spring flowers.",
      ogImgDateStamp: "03092023",
      description:
        "Looking for a home equity line of credit or HELOC rates in Washington state? Learn about rates and WaFd Bank's HELOC offering in the Seattle area and beyond.",
      city1: "Seattle",
      city2: "Kirkland",
      url1: "/locations/washington/seattle",
      url2: "/locations/washington/kirkland"
    },
    TX: {
      heroAltText: "3d printed homes under construction in North Austin, Texas.",
      ogImgDateStamp: "102924",
      description:
        "Looking for a home equity line of credit or HELOC rates in Texas? Learn about current rates and WaFd Bank's HELOC offering today.",
      city1: "Dallas",
      city2: "Austin",
      url1: "/locations/texas/dallas",
      url2: "/locations/texas/austin"
    }
  };

  // Determine if the indefinite article should be "a" or "an".
  // NOTE: for words that start with "U", it depends if it is a long or a short vowel sound;
  // for "You" sound, use "a", and for "Uh" sound use "an"
  const indefiniteArticle = (stateName) => {
    const vowels = ["A", "E", "I", "O", "U"];
    let article = "a";
    if (stateName !== "Utah" && vowels.includes(stateName[0])) {
      article = "an";
    }
    return article;
  };

  const heroChevronData = {
    id: "state-branches-hero-chevron",
    ...getHeroImgVariables(data),
    altText: `${pageData[StateCode].heroAltText}`,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            text: `Jump Into Projects With ${indefiniteArticle(StateName)} ${StateName} HELOC`,
            class: "text-white hero-heading"
          }
        },
        {
          id: 2,
          button: {
            id: "apply-now-search-zip-code-cta",
            class: "btn-white",
            containerClass: "mb-3",
            text: "Apply Now",
            type: "button",
            onClick: () => handleShowLOSearchModal()
          }
        },
        {
          id: 3,
          button: {
            id: "calculator-cta",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: `${StateName} Home Equity Loans`
    }
  ];

  const title = `HELOC Rates and Loans in ${StateName}`;
  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: pageData[StateCode].description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/heloc/" + stateData.Slug
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: `https://www.wafdbank.com/images/adsearch/states/heloc/og-heloc-${stateData.Slug}-${pageData[StateCode].ogImgDateStamp}.jpg`
      }
    ]
  };

  const AVG_PRICE = 100000;
  const calculatorData = {
    title: `${StateName} HELOC`,
    loanType: "HELOC",
    loanTerm: "10 Year INT. ONLY",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: AVG_PRICE,
    cashOutAmount: 0,
    loanAmount: AVG_PRICE
  };

  const faqAccordionData = {
    id: `${StateName.toLowerCase().replace(/\s/g, "-")}-state-heloc-faq-accordion`,
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "state-heloc-see-more-faqs-link",
    faqCategoryNames: ["State HELOC"],
    showContactBar: false
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      document.getElementById("faq-heloc-state-name").textContent = StateName;
    }, 1000);
    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  const helocVideoData = {
    vimeoId: "875369659",
    title: "What Can You Use a HELOC For? | WaFd Bank",
    class: "m-auto iframe w-100 border-radius-12"
  };

  const benefitsOfHomeEquityLineOfCredit = {
    greenCircleCheckmark: true,
    accountName: "heloc-benefits",
    items: [
      {
        id: 1,
        text: `<strong class="text-success">We pay closing costs</strong> for our existing WaFd home loan clients on lines of credit up to $250,000*`
      },
      {
        id: 2,
        text: `<strong class="text-success">Discount of 0.25%</strong> on your interest rate when you make automatic payments from your WaFd checking account`
      },
      {
        id: 3,
        text: `<strong class="text-success">Available for owner occupied, second homes, and investment properties</strong>`
      },
      {
        id: 4,
        text: `<strong class="text-success">No annual renewal fees</strong>`
      },
      {
        id: 5,
        text: `<strong class="text-success">Only pay interest on the amount borrowed**</strong>`
      }
    ]
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have.",
    statePage: {
      StateCode,
      StateName,
      stateData
    }
  };

  const cardsData = useCardBlogsData([
    "/blog/building-remodeling/heloc-for-home-improvement",
    "/blog/home-ownership/home-equity-loan-college-tuition",
    "/blog/home-ownership/how-to-qualify-for-a-heloc"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData);

  const articleCardsData = {
    sectionClass: "bg-white",
    hasGrowEffect: true,
    cards: featuredCardsData
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>{StateName} Home Equity Line of Credit (HELOC)</h1>
        <div className="row">
          <div className="col-md-7 col-lg-8">
            <h3>
              Whether you're renovating in{" "}
              <Link to={pageData[StateCode].url1} id="city-link-1">
                {pageData[StateCode].city1}
              </Link>
              , remodeling in{" "}
              <Link to={pageData[StateCode].url2} id="city-link-2">
                {pageData[StateCode].city2}
              </Link>
              , paying bills or taking a dream vacation, you can use your home's equity to finance what's next. A WaFd
              HELOC has no annual fee and is often more affordable than using credit cards or other loans. Our HELOCs
              are available for primary, second home, and investment properties, including jumbo HELOCs up to $700,000.
              To get started, apply online anytime, where you can securely upload your documents and receive status
              updates in our secure portal.
            </h3>
          </div>
          <div className="col-md-5 col-lg-4">
            <div className="bg-light border-radius-12 p-3">
              <div className="align-items-center justify-content-between mb-3 row">
                <h3 className="mb-0 col">Start Your HELOC Application</h3>
                <img src="/images/icons/icon-heloc.svg" alt="" className="col-auto" />
              </div>
              <Button
                class="btn-primary btn-block"
                containerClass="mb-3"
                type="button"
                onClick={handleShowLOSearchModal}
                text="Apply Now"
                id="heloc-apply-now-modal-cta"
                showIcon={false}
              />
              <LoanOfficerSearch isModalSearch={true} show={showLOSearchModal} handleClose={handleCloseLOSearchModal} />
              <Button
                url="/personal-banking/home-loans/mortgage-rates"
                id="mortgage-rates-cta-btn"
                class="btn-link btn-block"
                containerClass="mb-3"
                text="See Today's Rates"
              />
              <div>
                Already started your application?
                <br />
                <a
                  href="/personal-banking/home-loans/mortgage-login"
                  id="returning-borrower-link"
                  className="font-weight-bold text-decoration-none"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Return to HELOC application
                  <Icon name="arrow-right" class="ml-1" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <MortgageCalculator {...calculatorData} />

      <section className="bg-gray-10">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <VimeoVideoFrame {...helocVideoData} />
            </div>
            <div className="col-md-6">
              <h2 className="text-green-60">Benefits of a WaFd Home Equity Line of Credit</h2>
              <List {...benefitsOfHomeEquityLineOfCredit} />
              <p id="heloc-closing-cost-disclaimer" className="text-sm">
                * Closing costs for our existing WaFd home loan clients are paid only for primary or second/vacation
                homes, not on investment properties.
              </p>
              <p id="heloc-interest-disclaimer" className="text-sm">
                ** During the draw period, the borrower is only required to pay interest on those funds that they
                actually drew out and used.
              </p>
            </div>
          </div>
        </div>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <StretchedLinkCards {...articleCardsData} />
      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default StateHelocTemplate;
